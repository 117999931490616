

































































































































import { Club } from '@/modules/login/models/club.model';
import { Vue, Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { CancellationFormSettings } from '../models/cancellation-form-settings.model';
import { Membership } from '../models/membership.model';
import { CancellationReason } from '../models/cancellation-reason.model';
import { ToastHelper } from '@/shared/helpers/toast.helper';
import { CancellMembership, CancellMembershipsRequest } from '../models/cancell-memberships-request.model';
import dayjs from 'dayjs';

interface IMembershipModel {
    membershipId: string | null,
    reasonId: string | null,
    minDate: string | null,
    date: string | null,
    membershipErrorMessage: string | null,
    reasonErrorMessage: string | null,
    dateErrorMessage: string | null
}

interface IModel {
    cancelOnSeasonEnd: boolean,
    comment: string | null,
    membershipsToCancel: IMembershipModel[]
}

@Component({})
export default class MembershipCancellations extends Vue {

    isLoading = true;
    isSubmitting = false;
    model: IModel = {
        cancelOnSeasonEnd: true,
        comment: null,
        membershipsToCancel: [
            {
                membershipId: null,
                reasonId: null,
                minDate: null,
                date: null,
                membershipErrorMessage: null,
                reasonErrorMessage: null,
                dateErrorMessage: null
            }
        ]
    }

    @Action
    private loadCancellationFormSettings!: () => Promise<void>;
    @Action
    private loadMemberships!: () => Promise<void>;
    @Action
    private loadCancellationReasons!: () => Promise<void>;
    @Action
    private sendMembershipCancellations!: (payload: CancellMembershipsRequest) => Promise<void>;

    @Getter
    currentClub!: Club;

    @Getter
    membershipsToCancel!: Membership[];
    @Getter
    membershipCancellationFormSettings!: CancellationFormSettings | null;
    @Getter
    membershipCancellationReasons!: CancellationReason[];

    async created(): Promise<void> {
        await this.load();
    }

    get formText(): string {
        return this.membershipCancellationFormSettings?.headerText ?? '';
    }

    get allowCancellationCustomDate(): boolean {
        return this.membershipCancellationFormSettings?.enableWishEndDate ?? false;
    }

    get cancellationEndOptions(): { text: string, value: boolean, disabled: boolean }[] {
        return [
            { text: `${this.$t('membershipCancellations.onSeasonEnd')}`, value: true, disabled: false },
            { text: `${this.$t('membershipCancellations.onSelectDate')}`, value: false, disabled: !this.allowCancellationCustomDate },
        ]
    }

    get membershipOptions(): { value: string, text: string, disabled: boolean, index: number }[] {
        var result: { value: string, text: string, disabled: boolean, index: number }[] = [];

        this.membershipsToCancel.forEach((x) => {
            const selectedIndex = this.model.membershipsToCancel.findIndex(y => y.membershipId === x.id);
            const name = `${x.planName} (${dayjs(x.startDate).format('D-M-YYYY')} - ${x.endDate ? dayjs(x.endDate).format('D-M-YYYY') : ''})`;

            result.push({
                value: x.id,
                text: name,
                disabled: x.cancellationDate !== null || x.cancellationRecievedDate !== null || x.cancellationSentToProcessDate !== null,
                index: selectedIndex
            });
        })

        return result;
    }

    get cancelationReasonOptions(): { value: string, text: string }[] {
        return this.membershipCancellationReasons.map(x => ({
            value: x.id,
            text: x.title
        }));
    }

    get showDatePicker(): boolean {
        return !this.model.cancelOnSeasonEnd;
    }

    get showMembershipButton(): boolean {
        if (this.model.membershipsToCancel.findIndex(x => x.membershipId === null) !== -1) return false;

        const selectedCount = this.model.membershipsToCancel.filter(x => x.membershipId !== null).length;
        const availableCount = this.membershipOptions.filter(x => !x.disabled).length;

        return selectedCount < availableCount;
    }

    get membershipsUnableToCancel(): string[] {
        return this.membershipsToCancel.filter(x =>
            x.cancellationDate
            || x.cancellationRecievedDate
            || x.cancellationSentToProcessDate)
            .map(x => x.planName);
    }

    get getAlreadyCancelledMemberships(): string | null {
        const memberships = this.membershipsToCancel
            .filter(x => x.endDate)
            .map(x => ({
                planName: x.planName,
                endDate: x.endDate
            }));

        if (memberships.length === 0) {
            return null;
        }

        let text = '';
        for (let i = 0; i < memberships.length; i++) {
            let endDate = dayjs(memberships[i].endDate).format('YYYY-MM-DD');
            text = `${this.$t('membershipCancellations.membershipAlreadyCancelled')}`;
            text = text.replace('{0}', memberships[i].planName).replace('{1}', endDate);
        }

        return text;
    }

    get getMembershipsCancellationInProgress(): string | null {
        const memberships = this.membershipsToCancel
            .filter(x =>
                !x.cancellationDate
                && !x.cancellationRecievedDate
                && x.cancellationSentToProcessDate)
            .map(x => x.planName);

        if (memberships.length === 0) {
            return null;
        }

        if (memberships.length === 1) {
            let text = `${this.$t('membershipCancellations.membershipInProcessing')}`;
            return text.replace('{0}', memberships[0]);
        }

        const text = `${this.$t('membershipCancellations.membershipsInProcessing')}`
        const membershipNames = memberships.join(', ');

        return text.replace('{0}', membershipNames);
    }

    get disableSubmitButton(): boolean {

        for (let i = 0; i < this.model.membershipsToCancel.length; i++) {
            const membershipsToCancel = this.model.membershipsToCancel[i];
            if (membershipsToCancel.membershipId === null || membershipsToCancel.reasonId === null) {
                return true;
            }

            if (!this.model.cancelOnSeasonEnd && membershipsToCancel.date === null) {
                return true;
            }
        }

        return false;
    }

    get hasAnyMembershipsToCancel(): boolean {
        return this.membershipOptions.filter(x => x.disabled === false).length > 0;
    }

    removeMembershipFromList(index: number): void {
        this.model.membershipsToCancel.splice(index, 1);
    }

    membershipSelected(index: number): void {
        const id = this.model.membershipsToCancel[index].membershipId;

        if (!id) {
            this.model.membershipsToCancel[index].minDate = null;
            return;
        }

        const membership = this.membershipsToCancel.find(x => x.id === id);

        if (!membership) return;

        const minDate = dayjs().format('YYYY-MM-DD');
        this.model.membershipsToCancel[index].minDate = minDate;
    }

    showDeleteButton(index: number): boolean {
        if (this.model.membershipsToCancel[index].membershipId === null && this.model.membershipsToCancel.length > 1) {
            return true;
        }

        if (this.membershipCancellationReasons.length < 2) {
            return false;
        }

        if (this.model.membershipsToCancel.filter(x => x.membershipId !== null).length < 2) {
            return false;
        }

        return true;
    }

    dateChanged(val: string | null, index: number): void {
        this.model.membershipsToCancel[index].date = val;
    }

    addMembership(): void {
        this.model.membershipsToCancel.push({
            membershipId: null,
            reasonId: null,
            minDate: null,
            date: null,
            membershipErrorMessage: null,
            reasonErrorMessage: null,
            dateErrorMessage: null
        });
    }

    async load(): Promise<void> {
        try {
            this.isLoading = true;

            await Promise.all([
                this.loadCancellationFormSettings(),
                this.loadMemberships(),
                this.loadCancellationReasons(),
            ]);
        }
        catch {
            ToastHelper.showError(this.$bvToast, this.$t('shared.errorMessage'));
        }
        finally {
            this.isLoading = false;

        }
    }

    async submit(): Promise<void> {
        try {


            this.isSubmitting = true;

            const isValid = this.validate();

            if (!isValid) return;

            const confirmed = await this.$bvModal.msgBoxConfirm(
                `${this.$t('membershipCancellations.confirmMessage')}`,
                { okTitle: `${this.$t('shared.confirm')}`, cancelTitle: `${this.$t('shared.close')}` }
            );

            if (!confirmed) return;

            const membershipsToCancel: CancellMembership[] = [];

            this.model.membershipsToCancel.forEach(x => {
                if (x.membershipId && x.reasonId) {
                    membershipsToCancel.push({
                        id: x.membershipId,
                        reasonId: x.reasonId,
                        endDate: this.model.cancelOnSeasonEnd ? null : dayjs(x.date).format("YYYY-MM-DD")
                    });
                }
            })

            var model: CancellMembershipsRequest = {
                cancelOnSeasonEnd: this.model.cancelOnSeasonEnd,
                comment: this.model.comment,
                membershipsToCancel: membershipsToCancel
            };

            await this.sendMembershipCancellations(model);

            const successInfoText = this.membershipCancellationFormSettings?.directProcessing
                ? this.$t('membershipCancellations.membershipsCancelledSuccessful')
                : this.$t('membershipCancellations.membershipsSendToProcess');

            ToastHelper.showSuccess(this.$bvToast, successInfoText);

            await this.load();
        }
        catch {
            ToastHelper.showError(this.$bvToast, this.$t('shared.errorMessage'));
        }
        finally {
            this.isSubmitting = false;
        }
    }

    validate(): boolean {
        let isValid = false;

        this.model.membershipsToCancel.forEach(x => {
            x.membershipErrorMessage = !x.membershipId && x.reasonId
                ? `${this.$t('membershipCancellations.membershipRequired')}`
                : null;

            x.reasonErrorMessage = x.membershipId && !x.reasonId
                ? `${this.$t('membershipCancellations.reasonRequired')}`
                : null;

            x.dateErrorMessage = (x.membershipId || x.reasonId) && !x.date && !this.model.cancelOnSeasonEnd
                ? `${this.$t('membershipCancellations.dateRequired')}`
                : null;

            isValid = !x.membershipErrorMessage
                && !x.reasonErrorMessage
                && !x.dateErrorMessage;
        });

        return isValid;
    }
}
